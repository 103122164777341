import * as React from 'react';
import {Box, Button, Snackbar, Stack} from '@mui/material';
import './Header.css'
import {Link} from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import {useEffect, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {Modal} from "react-bootstrap";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {usersActions} from "../../store/user";
import Alert from '../Alert';


const Header = () => {
    const user = useSelector((state) => state.user);
    const [scrollTop, setScrollTop] = useState(0);
    const [language, setLanguage] = useState({label: "en", icon: "ge-flag.svg"});
    const [showLanguagesDropdown, setShowLanguagesDropdown] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [notification, setNotification] = useState({show: false, title: "", body: ""});
    const [value, setValue] = useState('1');
    const [authForm, setAuthForm] = useState({username: "", password: ""});
    const [signUpForm, setSignUpForm] = useState({
        name: "", surname: "", username: "", email: "", phone: "", password: ""
    });
    const dispatch = useDispatch();
    const [error, setError] = useState({
        email: {text: "", show: false},
        username: {text: "", show: false},
        password: {text: "", show: false},
        phone: {text: "", show: false}
    })
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleScroll = () => {
        setScrollTop(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handlers = {
        login(e) {
            e.preventDefault();
            axios.post('/api/auth', authForm)
                .then((res) => {
                    const data = res.data.data;
                    dispatch(usersActions.changeIsLoggedIn(true));
                    dispatch(usersActions.saveUserData(data));
                    setShowAuthModal(false);
                    handleClick()
                })
                .catch(() => {

                })
        }, signUp(e) {
            e.preventDefault();
            axios.post('/api/sign-up', signUpForm)
                .then(() => {
                    setNotification({show: true, title: "მოთხოვნა წარმატებით დამუშავდა", body: "თქვენ წარმატებით დარეგისტრირდით, გთხოვთ გაიაროთ ავტორიზაცია"});
                    setSignUpForm({name: "", surname: "", username: "", email: "", password: "", phone: ""});
                    setShowAuthModal(false);
                })
                .catch(() => {
                    setNotification({show: true, title: "შეცდომა", body: "რაღაც გაუთვალისწინებელი მოხდა"})
                })
        }, checkUsername() {
            axios(`/api/check/unique-record?type=username&value=${signUpForm.username}`)
                .then((res) => {
                    const data = res.data;
                    const status = data.accepted;
                    if (status) {
                        setError((prev) => ({...prev, username: {show: true, text: "", type: "success"}}))
                    } else {
                        setError((prev) => ({...prev, username: {show: true, text: "Username is busy", type: "error"}}))
                    }
                })
                .catch(() => {

                })
        }, checkPhone() {
            axios(`/api/check/unique-record?type=phone&value=${signUpForm.phone}`)
                .then((res) => {
                    const data = res.data;
                    const status = data.accepted;
                    if (status) {
                        setError((prev) => ({...prev, phone: {show: true, text: "", type: "success"}}))
                    } else {
                        setError((prev) => ({...prev, phone: {show: true, text: "Phone already used", type: "error"}}))
                    }
                })
                .catch(() => {

                })
        }, checkEmail() {
            axios(`/api/check/unique-record?type=email&value=${signUpForm.email}`)
                .then((res) => {
                    const data = res.data;
                    const status = data.accepted;
                    if (status) {
                        setError((prev) => ({...prev, email: {show: true, text: "", type: "success"}}))
                    } else {
                        setError((prev) => ({...prev, email: {show: true, text: "Email is used", type: "error"}}))
                    }
                })
                .catch(() => {

                })
        }
    }

    return (<header className={`header-container ${scrollTop > 20 ? 'header-background' : ''}`}>
        <div className={'header-content'}>
            <Link to={'/'}><img src={'/static/icons/logo1.png'} alt="logo" className={"header-logo"}/></Link>
            <nav className={"navbar-container"}>
                <ul className={"nav-list"}>
                    <li><Link to={"/"}>მთავარი</Link></li>
                    <li><Link to={'/catalogue'}>კატალოგი</Link></li>
                    <li><Link to={"/register-as-dealer"}>დილერად რეგისტრაცია</Link></li>
                    <li><Link to={"/contact"}>კონტაქტი</Link></li>
                    {/*<li><Link to={"/tools/extension"}>Trading Tool</Link></li>*/}
                </ul>
            </nav>
            <div className='header-buttons'>
                <div className='language-switcher'>
                    <div className='selected-language'>
                        <div className='active-language-icon'
                             onClick={() => setShowLanguagesDropdown(!showLanguagesDropdown)}
                             style={{backgroundImage: `url('/static/icons/${language.icon}')`}}></div>
                    </div>
                    {showLanguagesDropdown &&
                        <OutsideClickHandler onOutsideClick={() => setShowLanguagesDropdown(false)}>
                            <div className='languages-dropdown'>
                                <ul className='languages-list'>
                                    <li onClick={() => {
                                        setLanguage({label: "ge", icon: "ge-flag.svg"});
                                        setShowLanguagesDropdown(false);
                                    }}
                                        style={{backgroundImage: `url('/static/icons/ge-flag.svg')`}}></li>
                                    <li onClick={() => {
                                        setLanguage({label: "ru", icon: "ru-flag.svg"});
                                        setShowLanguagesDropdown(false);
                                    }}
                                        style={{backgroundImage: `url('/static/icons/ru-flag.svg')`}}></li>
                                    <li onClick={() => {
                                        setLanguage({label: "en", icon: "us-flag.svg"});
                                        setShowLanguagesDropdown(false);
                                    }}
                                        style={{backgroundImage: `url('/static/icons/us-flag.svg')`}}></li>
                                </ul>
                            </div>
                        </OutsideClickHandler>}
                </div>
                {user.isLoggedIn ? (<div className={"user-avatar"}>
                        <div className={"avatar-box"}><span>{user.initials}</span></div>
                    </div>) : (
                    <Button variant="contained" startIcon={<PersonIcon/>} onClick={() => setShowAuthModal(true)}>შესვლა</Button>)}
            </div>
        </div>
        <Modal show={showAuthModal} onHide={() => setShowAuthModal(false)} centered size={"md"}>
            <Modal.Body>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="შესვლა" value="1"/>
                            <Tab label="რეგისტრაცია" value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <h3>შესვლა</h3>
                        <form className={"login-form"} onSubmit={handlers.login}>
                            <div className={"form-group"}>
                                <label>მომხ. სახელი</label>
                                <input type={"text"} required onChange={(e) => setAuthForm((prev) => ({
                                    ...prev, username: e.target.value
                                }))}
                                       className={"form-control"} name={"username"} id={"username"}
                                       value={authForm.username}/>
                            </div>
                            <div className={"form-group"} style={{marginTop: "25px"}}>
                                <label>პაროლი</label>
                                <input type={"password"} required min={6} onChange={(e) => setAuthForm((prev) => ({
                                    ...prev, password: e.target.value
                                }))}
                                       className={"form-control"} name={"password"} id={"password"}
                                       value={authForm.password}/>
                            </div>
                            <Button variant={"outlined"} type={"submit"} style={{width: "100%", marginTop: "25px"}}>შესვლა</Button>
                        </form>
                        <div
                            style={{display: "flex", justifyContent: "flex-end", marginTop: "20px", color: "blue"}}>
                            <span style={{cursor: "pointer"}}>პაროლის აღდგენა</span>
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                        <h3>რეგისტრაცია</h3>
                        <form className={"login-form"} onSubmit={handlers.signUp}>
                            <div className={"form-group"}>
                                <label>სახელი</label>
                                <input type={"test"} required
                                       onChange={(e) => setSignUpForm((prev) => ({...prev, name: e.target.value}))}
                                       className={"form-control"} name={"name"} id={"name"}
                                       value={signUpForm.name}/>
                            </div>
                            <div className={"form-group"} style={{marginTop: "25px"}}>
                                <label>გვარი</label>
                                <input type={"text"} required onChange={(e) => setSignUpForm((prev) => ({
                                    ...prev, surname: e.target.value
                                }))}
                                       className={"form-control"} name={"surname"} id={"surname"}
                                       value={signUpForm.surname}/>
                            </div>
                            <div className={"form-group"} style={{marginTop: "25px"}}>
                                <label>მომხ. სახელი</label>
                                <input type={"test"} required onChange={(e) => setSignUpForm((prev) => ({
                                    ...prev, username: e.target.value
                                }))}
                                       className={`form-control ${error.username.show && (error.username.type === "success" ? "is-valid" : "is-invalid")}`}
                                       name={"sign-up-username"} id={"sign-up-username"}
                                       value={signUpForm.username} onBlur={handlers.checkUsername}/>
                                <div className="invalid-feedback">
                                    {error.username.text}
                                </div>
                            </div>
                            <div className={"form-group"} style={{marginTop: "25px"}}>
                                <label>ტელეფონი</label>
                                <input type={"text"} required
                                       onChange={(e) => setSignUpForm((prev) => ({...prev, phone: e.target.value}))}
                                       className={`form-control ${error.phone.show && (error.phone.type === "success" ? "is-valid" : "is-invalid")}`}
                                       name={"phone"} id={"phone"}
                                       value={signUpForm.phone} onBlur={handlers.checkPhone}/>
                                <div className="invalid-feedback">
                                    {error.phone.text}
                                </div>
                            </div>
                            <div className={"form-group"} style={{marginTop: "25px"}}>
                                <label>ელ. ფოსტა</label>
                                <input type={"email"} required
                                       onChange={(e) => setSignUpForm((prev) => ({...prev, email: e.target.value}))}
                                       className={`form-control ${error.email.show && (error.email.type === "success" ? "is-valid" : "is-invalid")}`}
                                       name={"email"} id={"email"}
                                       value={signUpForm.email} onBlur={handlers.checkEmail}/>
                                <div className="invalid-feedback">
                                    {error.email.text}
                                </div>
                            </div>
                            <div className={"form-group"} style={{marginTop: "25px"}}>
                                <label>პაროლი</label>
                                <input type={"password"} required min={6} onChange={(e) => setSignUpForm((prev) => ({
                                    ...prev, password: e.target.value
                                }))}
                                       className={"form-control"} name={"sign-up-password"} id={"sign-up-password"}
                                       value={signUpForm.password}/>
                            </div>
                            <Button variant={"outlined"} type={"submit"} style={{width: "100%", marginTop: "25px"}}>რეგისტრაცია</Button>
                        </form>
                    </TabPanel>
                </TabContext>
            </Modal.Body>
        </Modal>
        <Modal show={notification.show} onHide={() => setNotification((prev) => ({...prev, show: false}))} size="md">
            <Modal.Header><h4>{notification.title}</h4></Modal.Header>
            <Modal.Body>{notification.body}</Modal.Body>
        </Modal>
        <Stack>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    You successfully logged in
                </Alert>
            </Snackbar>
        </Stack>
    </header>)
}

export default Header