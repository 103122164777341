import './App.css';
import Footer from './Common/Footer/Footer';
import Header from "./Common/Header/Header";
import Catalogue from './pages/catalogue';
import Contact from './pages/contact';
import DealersRegistration from './pages/dealersRegistration';
import MainPage from "./pages/main";
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import CarDetails from "./pages/carDetails";
import {useEffect, useState} from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "./store/user";
import AsideModal from "./Common/AsideModal/AsideModal";
import WatchList from './pages/watchList';
import RequiredConsultations from './pages/RequiredConsultations';
import BuyNowRequests from './pages/BuyNowRequests';
import SearchContainer from './pages/SearchContainer';

function App() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user)
    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        const width = window.innerWidth;
        setScreenWidth(width);
    }, []);

    useEffect(() => {
        axios.get('/api/user')
            .then((res) => {
                const data = res.data.data;
                dispatch(usersActions.changeIsLoggedIn(true))
                dispatch(usersActions.saveUserData(data));
            })
            .catch(() => {
                dispatch(usersActions.changeIsLoggedIn(false))
            })
    }, []);

    return (
        <div className="App">
            <Header isLoggedIn={user.isLoggedIn} />
            {
                (user.isLoggedIn || screenWidth < 1025) && <AsideModal isLoggedIn={user.isLoggedIn}/>
            }
            <main className={'page-content'}>
                <Switch>
                    <Route path={"/"} exact><MainPage /></Route>
                    <Route path={"/catalogue"} exact><Catalogue /></Route>
                    <Route path={"/register-as-dealer"} exact><DealersRegistration /></Route>
                    <Route path='/contact'><Contact /></Route>
                    <Route path='/container-tracking'><SearchContainer /></Route>
                    <Route path={"/lot/:id"}><CarDetails /></Route>
                    <>
                        <Route path={"/my-page/watch"}><WatchList /></Route>
                        <Route path={"/my-page/consultations"}><RequiredConsultations /></Route>
                        <Route path={"/my-page/buy-now"}><BuyNowRequests /></Route>
                    </>
                </Switch>
            </main>
            <Footer />
        </div>
    );
}

export default App;
