import { Button, FormControl, Input, InputAdornment, InputLabel } from '@mui/material'
import '../styles/registerAsDealer/RegisterAsDealer.css'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { useState } from 'react';
import Rotate from 'react-reveal/Rotate';
import axios from "axios";
import {Modal} from "react-bootstrap";

const DealersRegistration = () => {
    const [formData, setFormData] = useState({ name: "", email: "", phone: "", cars_per_month: "" });
    const [notification, setNotification] = useState({show: false, title: "", body: ""});

    const dealerRegistration = (e) => {
        e.preventDefault();
        axios.post('/api/dealer/registration', formData)
            .then(() => {
                setNotification({show: true, title: "მოთხოვნა წარმატებით გაიგზავნა", body: "კონსულტაციის მოთხოვნა გაგზავნილია. დაელოდეთ ჩვენი წარმომადგენლის ზარს"});
                setFormData({ name: "", email: "", phone: "", cars_per_month: "" });
            })
            .catch(() => {
                setNotification({show: true, title: "შეცდომა", body: "რაღაც წარუმატებელი მოხდა"})
                setFormData({ name: "", email: "", phone: "", cars_per_month: "" });
            })
    }

    return (
        <div className="register-as-dealer-container">
            <div className="register-as-dealer-banner" style={{ backgroundImage: "url('/static/images/dealers-banner.png')" }}>
                <h1>გახდი ჩვენი დილერი და გამოიმუშავე თანხა ავტო იმპორტით</h1>
            </div>
            <div className='dealer-registration-form-container'>
                <h4>დილერად რეგისტრაცია</h4>
                <form className='dealer-registration-form' onSubmit={dealerRegistration}>
                    <div className='form-group' style={{ marginTop: "20px" }}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                ჩაწერეთ სახელი
                            </InputLabel>
                            <Input
                                id="name"
                                required
                                value={formData.name}
                                onChange={(e) => !(/^[0-9]+$/.test(e.target.value)) && setFormData((prev) => ({ ...prev, name: e.target.value }))}
                                type='text'
                                name='name'
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccountCircleIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className='form-group' style={{ marginTop: "20px" }}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                ჩაწერეთ ელ. ფოსტა
                            </InputLabel>
                            <Input
                                id="email"
                                required
                                value={formData.email}
                                onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                                type='email'
                                name='email'
                                startAdornment={
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className='form-group' style={{ marginTop: "20px" }}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                ჩაწერეთ ტელ. ნომერი
                            </InputLabel>
                            <Input
                                required
                                type='text'
                                value={formData.phone}
                                onChange={(e) => (/^[0-9]+$/.test(e.target.value) || e.target.value === "") && setFormData((prev) => ({ ...prev, phone: e.target.value }))}
                                name='phone'
                                id="phone"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PhoneAndroidIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className='form-group' style={{ marginTop: "20px" }}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                მანქანების რაოდენობა თვეში
                            </InputLabel>
                            <Input
                                required
                                type='number'
                                value={formData.cars_per_mongth}
                                onChange={(e) => setFormData((prev) => ({ ...prev, cars_per_mongth: e.target.value }))}
                                name='cars-per-month'
                                id="cars-per-month"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <DriveEtaIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <Button type='submit' style={{ marginTop: "20px", width: "50%" }} startIcon={<HowToRegIcon />} variant='contained'>მოთხოვნის გაგზავნა</Button>
                </form>
            </div>

            <div className='dealer-services-container'>
                <div className='dealers-service-content'>
                    <Rotate top left>
                        <img alt="banner" src='/static/images/extension-service.png' className='extension-service-image' />
                    </Rotate>
                    <Rotate top right>
                        <p className='dealers-service-text'>ჩვენი ექსთენშენი არის Copart და IAAI აუქციონებთან სამუშაო პროგრამა, რომელიც იძლევა უამრავ სასარგებლო ინფორმაციას აუტომობილის შესახებ, როგორიცაა: ლოტის ისტორია, seller reserve, აუქციონის დანამატი და დოკუმენტის ტიპი. ასევე საშუალებას აძლევს დილერს ქონდეს მისი უნიკალური გარემო Copart და IAAI აუქციონებზე</p>
                    </Rotate>
                </div>
                <div className='dealers-service-content reverce' style={{marginTop: "130px"}}>
                    <Rotate top right>
                        <p className='dealers-service-text'>მიიღე წვდომა შენს პირად კაბინეტზე სადაც საშუალება გექნება ნებისმიერ დროს შეამოწმო და აკონტროლო შენს მიერ შეძენილი ავტომობილების სურათები, მდებარეობა, ხარჯები და ა.შ.</p>
                    </Rotate>
                    <Rotate top left>
                        <img alt="banner" src='/static/images/dealers-dashboard.png' className='dashboard-service-image' />
                    </Rotate>
                </div>
                <div className='dealers-service-content' style={{marginTop: "130px"}}>
                    <Rotate top left>
                        <img alt="banner" src='/static/images/Cargo-containers.webp' className='extension-service-image' />
                    </Rotate>
                    <Rotate top right>
                        <p className='dealers-service-text'>მიიღე ტრანსპორტირების შენზე მორგებული ფასები, ჩვენ ჩვენი პარტნიორი დილერებისთვის გვაქვს განსაკუთრებული შეთავაზება ტრანსპორტირებაზე.</p>
                    </Rotate>
                </div>
            </div>
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({...prev, show: false}))} size="md">
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
            </Modal>
        </div>
    )
}

export default DealersRegistration