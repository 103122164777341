import './Services.css'
import Zoom from 'react-reveal/Zoom';

const Services = () => {
    return (
        <section className="services-container">
            <h4 className="section-title">ჩვენი სერვისები</h4>
            <div className='services-sections-list'>
                <Zoom>
                    <div className='services-item'>
                        <img src='/static/icons/consultation.svg' alt='service-images' className='services-icons consultation-icon' />
                        <p className={"services-title"}>კონსულტაცია</p>
                        <p className='service-description-text'>ჩვენ ყურადღებით შევარჩევთ მანქანებს თქვენთვის სასურველი ქვეყნის აუქციონზე</p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='services-item'>
                        <img src='/static/icons/buyCar.svg' alt='service-images' className='services-icons'/>
                        <p className={"services-title"}>შეძენა</p>
                        <p className='service-description-text'>მანქანების შეძენა ხდება ადგილობრივ საბაზრო
                            ღირებულებასთან შედარებით 20-30%-ით ნაკლებად</p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='services-item'>
                        <img src='/static/icons/funding.png' alt='service-images' className='services-icons'/>
                        <p className={"services-title"}>დაფინანსება</p>
                        <p className='service-description-text'>ავტომობილის ღირებულების 60%-ის დაფინანსება ფოთამდე</p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='services-item'>
                        <img src='/static/icons/transportation.svg' alt='service-images' className='services-icons'/>
                        <p className={"services-title"}>ტრანსპორტირება</p>
                        <p className='service-description-text'>ჩვენ ვუზრუნველვყოფთ თქვენი მანქანის სწრაფ და უსაფრთხო
                            ტრანსპორტირებას.</p>
                    </div>
                </Zoom>
            </div>
        </section>
    )
}

export default Services